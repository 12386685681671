import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr';

export default class extends Controller {
    connect() {
        flatpickr(this.element, {
            dateFormat: 'd/m/Y',
            minDate: new Date(),
            locale: French,
            disableMobile: true,
        });
    }
}
