import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [];

    connect() {
        this.hideCarBlocks();
        this.showCarBlocks(
            this.element.querySelector(
                '[name="circuit_pricing_information[transports][pricingTransportByCar][parkingPriceEnum]"]:checked',
            ).value,
        );
    }

    refreshCarBlocks(event) {
        const item = event.target;
        this.hideCarBlocks();
        this.showCarBlocks(item.value);
    }

    hideCarBlocks() {
        const blocks = this.element.querySelectorAll('[data-parking-price]');
        blocks.forEach((element) => {
            element.classList.add('hidden');
        });
    }

    showCarBlocks(typeId) {
        const inputSelectedType = document.querySelectorAll(
            '[data-parking-price]',
        );
        const matchingElements = Array.from(inputSelectedType).filter(
            (element) => {
                const values = element
                    .getAttribute('data-parking-price')
                    .split(',');

                return values.includes(typeId);
            },
        );
        matchingElements.forEach((element) => {
            element.classList.remove('hidden');
        });
    }
}
