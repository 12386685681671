import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['searchInput', 'items'];

    connect() {
        this.filterItems();
    }
    filterItems() {
        const searchTerm = this.searchInputTarget.value.toLowerCase();
        let hasVisibleItems = false;

        this.itemsTarget.querySelectorAll('input').forEach((item) => {
            const label = item.nextSibling.textContent.toLowerCase();
            const matches = label.includes(searchTerm);

            item.parentElement.classList.toggle('hidden', !matches);
            if (matches) hasVisibleItems = true;
        });
    }

    clearAll() {
        const checkboxes = this.itemsTarget.querySelectorAll('input');

        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
    }
}
