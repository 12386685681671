import { Controller } from '@hotwired/stimulus';

const activeClass = [
    'text-content-brand',
    'border-solid',
    'border-border-brand',
    'border-b-4',
];
const inactiveClass = 'text-content-neutral';
const quietClass = 'text-content-quiet';

export default class extends Controller {
    static targets = [
        'futureBooking',
        'pastBooking',
        'needsPaymentBooking',
        'past',
        'future',
        'needsPayment',
        'empty',
    ];

    initialize() {
        if (this.needsPaymentBookingTargets.length !== 0) {
            this.showNeedsPaymentBookings();
        } else {
            this.showFutureBookings();
        }
    }

    showFutureBookings(e) {
        if (e) e.preventDefault();

        if (this.futureBookingTargets.length === 0) {
            return;
        }

        this.activateFutureBookingTab();

        this.needsPaymentBookingTargets.forEach((booking) =>
            booking.classList.add('hidden'),
        );
        this.futureBookingTargets.forEach((booking) =>
            booking.classList.remove('hidden'),
        );
        this.pastBookingTargets.forEach((booking) =>
            booking.classList.add('hidden'),
        );
    }

    showPastBookings(e) {
        e.preventDefault();
        if (this.pastBookingTargets.length === 0) {
            return;
        }
        this.emptyTarget.classList.add('hidden');

        this.activatePastBookingTab();

        this.needsPaymentBookingTargets.forEach((booking) =>
            booking.classList.add('hidden'),
        );
        this.futureBookingTargets.forEach((booking) =>
            booking.classList.add('hidden'),
        );
        this.pastBookingTargets.forEach((booking) =>
            booking.classList.remove('hidden'),
        );
    }

    showNeedsPaymentBookings(e) {
        if (e) e.preventDefault();

        if (this.needsPaymentBookingTargets.length === 0) {
            return;
        }
        this.emptyTarget.classList.add('hidden');

        this.activateNeedsPaymentBookingTab();

        this.futureBookingTargets.forEach((booking) =>
            booking.classList.add('hidden'),
        );
        this.pastBookingTargets.forEach((booking) =>
            booking.classList.add('hidden'),
        );
        this.needsPaymentBookingTargets.forEach((booking) =>
            booking.classList.remove('hidden'),
        );
    }

    activateFutureBookingTab() {
        this.futureTarget.classList.add(...activeClass);
        this.pastTarget.classList.remove(...activeClass);
        this.needsPaymentTarget.classList.remove(...activeClass);

        if (this.futureTarget.classList.contains(inactiveClass)) {
            this.futureTarget.classList.remove(inactiveClass);
        }

        if (this.futureTarget.classList.contains(quietClass)) {
            this.futureTarget.classList.remove(quietClass);
        }

        if (this.pastBookingTargets.length === 0) {
            this.pastTarget.classList.add(quietClass);
        } else {
            this.pastTarget.classList.add(inactiveClass);
        }

        if (this.needsPaymentBookingTargets.length === 0) {
            this.needsPaymentTarget.classList.add(quietClass);
        } else {
            this.needsPaymentTarget.classList.add(inactiveClass);
        }
    }

    activatePastBookingTab() {
        this.pastTarget.classList.add(...activeClass);
        this.futureTarget.classList.remove(...activeClass);
        this.needsPaymentTarget.classList.remove(...activeClass);

        if (this.pastTarget.classList.contains(inactiveClass)) {
            this.pastTarget.classList.remove(inactiveClass);
        }

        if (this.pastTarget.classList.contains(quietClass)) {
            this.pastTarget.classList.remove(quietClass);
        }

        if (this.futureBookingTargets.length === 0) {
            this.futureTarget.classList.add(quietClass);
        } else {
            this.futureTarget.classList.add(inactiveClass);
        }

        if (this.needsPaymentBookingTargets.length === 0) {
            this.needsPaymentTarget.classList.add(quietClass);
        } else {
            this.needsPaymentTarget.classList.add(inactiveClass);
        }
    }

    activateNeedsPaymentBookingTab() {
        this.needsPaymentTarget.classList.add(...activeClass);
        this.futureTarget.classList.remove(...activeClass);
        this.pastTarget.classList.remove(...activeClass);

        if (this.needsPaymentTarget.classList.contains(inactiveClass)) {
            this.needsPaymentTarget.classList.remove(inactiveClass);
        }

        if (this.needsPaymentTarget.classList.contains(quietClass)) {
            this.needsPaymentTarget.classList.remove(quietClass);
        }

        if (this.futureBookingTargets.length === 0) {
            this.futureTarget.classList.add(quietClass);
        } else {
            this.futureTarget.classList.add(inactiveClass);
        }

        if (this.pastBookingTargets.length === 0) {
            this.pastTarget.classList.add(quietClass);
        } else {
            this.pastTarget.classList.add(inactiveClass);
        }
    }
}
