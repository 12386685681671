import { Controller } from '@hotwired/stimulus';
import autoComplete from '@tarekraafat/autocomplete.js';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['url'];

    static values = {
        requirePlaceId: Boolean,
    };

    connect() {
        this.initializeAutocomplete();
    }

    initializeAutocomplete() {
        const autocompletes = document.querySelectorAll(
            '[data-autocomplete-url]',
        );
        autocompletes?.forEach((autocomplete) => {
            const autoCompleteJS = new autoComplete({
                selector: `#${autocomplete.id}`,
                data: {
                    src: async (query) => {
                        try {
                            const sourceUrl =
                                autoCompleteJS.input.attributes.getNamedItem(
                                    'data-autocomplete-url',
                                ).value;
                            const withPlaceId = this.requirePlaceIdValue
                                ? '&withPlaceId=true'
                                : '';
                            const source = await fetch(
                                `${sourceUrl}?term=${query}${withPlaceId}`,
                            );
                            return await source.json();
                        } catch (error) {
                            return error;
                        }
                    },
                    keys: ['name'],
                },
                resultsList: {
                    class: 'absolute w-full top-[50px] left-0 z-40 bg-container-quiet border border-solid border-border-quiet',
                },
                resultItem: {
                    highlight: true,
                    class: 'p-s cursor-pointer block relative z-40 hover:text-content-brand',
                },
                wrapper: false,
                debounce: 300,
                events: {
                    input: {
                        selection: (event) => {
                            const name = event.detail.selection.value.name;
                            autoCompleteJS.input.value = name;
                            if (
                                this.requirePlaceIdValue &&
                                event.detail.selection.value.placeId
                            ) {
                                const placeSelectedEvent = new CustomEvent(
                                    'place:selected',
                                    {
                                        detail: {
                                            name: name,
                                            placeId:
                                                event.detail.selection.value
                                                    .placeId,
                                        },
                                        bubbles: true,
                                    },
                                );
                                autoCompleteJS.input.dispatchEvent(
                                    placeSelectedEvent,
                                );
                            }
                        },
                    },
                },
            });
        });
    }
}
