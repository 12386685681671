import { Controller } from '@hotwired/stimulus';

export default class FaqController extends Controller {
    static targets = ['faqNav'];

    handleHeadingClick(event) {
        event.preventDefault();
        this.toggleSection(event.currentTarget.closest('section'));
    }
    handleLinkClick(event) {
        event.preventDefault();

        const href = event.currentTarget.getAttribute('href');
        this.toggleSection(this.element.querySelector(href));
    }

    toggleSection(section) {
        section.classList.remove('desktop:hidden');
        const questions = section.querySelector('ul');
        if (questions) questions.classList.remove('hidden');

        Array.from(section.parentNode.children).forEach((sibling) => {
            if (sibling !== section) {
                sibling.classList.add('desktop:hidden');
                sibling.querySelector('ul')?.classList.add('hidden');
            }
        });

        this.faqNavTarget
            .querySelector(`[href="#${section.id}"]`)
            .classList.add('bg-secondary-regular-container-hover');
        this.faqNavTarget.querySelectorAll(':scope > a').forEach((link) => {
            if (link.getAttribute('href') !== `#${section.id}`) {
                link.classList.remove('bg-secondary-regular-container-hover');
            }
        });
    }
}
